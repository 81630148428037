import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Logo } from '../../../theme/LogoFooter';
import GatsbyLink from '../GatsbyLink';
import RenderContent from '../RenderContent';
import { SocialLinks } from '../SocialLinks'
import './Footer.scss';
import '../../../theme/Footer.scss';
import { decodeEntities } from '../../utils/helpers';
const isClient = typeof window !== 'undefined';
import loadable from '@loadable/component'

const CookieNotice = (props) => {
  const { privacyCookiePolicyNotice, privacyCookiePolicyNoticeText } = props.options
  if (privacyCookiePolicyNotice) {
    const CookiePolicy = loadable(() => import(`../CookiePolicy/index.js`))
    return <CookiePolicy content={privacyCookiePolicyNoticeText}/>
  }
  return null
}

class Footer extends Component {
  sendEmail(event){
    event.preventDefault()
    const { data } = this.props
    const { email } = data.siteSettings.options.locations[0]
      window.location = `mailto:${decodeEntities(email)}`;
  }
  render() {
    const { data, location = {} } = this.props;
    const {
      legalNavigation,
      footerNavigation,
      siteSettings,
      wordpressWpSettings
    } = data;
    const { items: legalNav } = legalNavigation;
    const { items: footerNav } = footerNavigation
    const { footerTagline, navigationInFooter, contactDetailsInFooter, privacyCookiePolicyNotice, privacyCookiePolicyNoticeText } = siteSettings.options
    const {
      email,
      phone,
      addressDisplay
    } = siteSettings.options.locations[0];
    const currentYear = (new Date()).getFullYear();

    return (
      <>
      <footer>
        <div className="footer-main">
          <div className="inner">
            <div className="wrap">

              {contactDetailsInFooter &&
                <div className="contact-details">
                  {email && <div><a href="#" className="email" onClick={(event) => this.sendEmail(event)}><span dangerouslySetInnerHTML={{__html: email}} /></a></div>}
                  {addressDisplay && <div className="address">{addressDisplay}</div>}
                  {phone && <div><a
                    href={`tel:${phone.replace(/[^A-Z0-9]/ig, "")}`}
                    className='control-call'
                    aria-label="Call Now"
                  >{phone}</a></div>}
                </div>
              }

              {navigationInFooter &&
                <ul className="footer-nav">
                {footerNav  && footerNav.map((item, index) => (
                  <li key={index}><GatsbyLink to={item.url} aria-label={item.title} decode={true}>{item.title}</GatsbyLink></li>
                ))
                }
                </ul>
              }

              <div className="logos">
                <Logo />
                {footerTagline && <RenderContent className="tagline" content={footerTagline}/>}
              </div>
            </div>
          </div>
        </div>
        <div className="disclaimers">
           <div className="wrap">
              {siteSettings.options.socialLinksFooter &&
                <SocialLinks/>
               }
               {legalNav &&
                 <div className="navigation">
                   <ul>
                     {legalNav.map((item, index) => (
                       <li key={index}><GatsbyLink to={item.url} decode={true}>{item.title}</GatsbyLink></li>
                     ))}
                   </ul>
                 </div>
               }

               <div className="copyright">
                 <span>©{currentYear} <strong>{wordpressWpSettings.title}</strong>, All rights reserved</span>
               </div>
           </div>
         </div>
      </footer>
      <CookieNotice options={siteSettings.options}/>
      </>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        legalNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "legal-navigation" }) {
          items {
            title
            url
            classes
            slug: object_slug
          }
        },
        footerNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "footer-navigation" }) {
          items {
            title
            url
            classes
            slug: object_slug
            children: wordpress_children {
              title
              url
              classes
              slug: object_slug
            }
          }
        },
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            locations {
              addressDisplay
              email
              phone
            }
            footerTagline
            socialLinksFooter
            navigationInFooter
            contactDetailsInFooter
            privacyCookiePolicyNotice
            privacyCookiePolicyNoticeText
          }
        },
        wordpressWpSettings {
          title
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
