export const getPageSlugFromWpLink = (wpLink, wordPressUrl) => {
  if (!wpLink) return null;
  return wpLink.replace(wordPressUrl, '');
}

export const slugify = (string) => {
  return string.toLowerCase().replace(/ /g, '-')
}
export const trailingSlash = (url) => {
  return url.endsWith("/") ? url : `${url}/`
}

String.prototype.replaceAll = function(str1, str2, ignore) {
    return this.replace(new RegExp(str1.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g,"\\$&"),(ignore?"gi":"g")),(typeof(str2)=="string")?str2.replace(/\$/g,"$$$$"):str2);
}

export const isBrowser = typeof window !== `undefined`

export const scrollTo = (elementY, duration) => {
  if (typeof window !== 'undefined') {
    const startingY = window.pageYOffset;
    const diff = elementY - startingY;
    let start;

    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp;
      // Elapsed milliseconds since start of scrolling.
      const time = timestamp - start;
      // Get percent of completion in range [0, 1].
      const percent = Math.min(time / duration, 1);
      window.scrollTo({
        top: startingY + diff * percent,
        left: 0,
        behaviour: 'smooth'
      });

      // Proceed with animation as long as we wanted it to.
      if (time < duration) {
        window.requestAnimationFrame(step);
      }
    })
  }
}

export const objectToQuerystring = (obj) => {
  return Object.keys(obj).reduce(function (str, key, i) {
    var delimiter, val;
    delimiter = (i === 0) ? '?' : '&';
    key = encodeURIComponent(key);
    val = encodeURIComponent(obj[key]);
    return [str, delimiter, key, '=', val].join('');
  }, '');
}

export const getUrlVars = () => {
    var vars = [], hash;
    var query_string = isClient && window.location.search;

    if (query_string) {
        var hashes = query_string.slice(1).split('&');
        for (var i = 0; i < hashes.length; i++) {
            hash = hashes[i].split('=');
            vars[hash[0]] = hash[1];
        }

        return vars;
    } else {
        return false;
    }
}

export const isClient = typeof window !== 'undefined';


export const compileQuery = (newKey, newValue = 'null', remove = null) => {
  if (isClient) {
    var params = getUrlVars()
  }
  var queryString = []
  if (params) {
    //Add new
    !params[newKey] && newValue != 'null' && queryString.push(`${newKey}=${newValue}`)
    //Update existing
    Object.keys(params).forEach(key => {
      var thisValue = newKey === key ? newValue : params[key]
      thisValue != 'null' && remove != key && queryString.push(`${key}=${thisValue}`)
    })
  } else {
    //Add new
    newValue != 'null' && queryString.push(`${newKey}=${newValue}`)
  }

  return queryString.length > 0 ? '?' + queryString.join('&') : ''
}

export const edgeTest = () => {
  //Edge < 40 bug fix
  const appVersion = isClient && navigator.appVersion.indexOf('Edge') > -1
  const appVersionNumber = appVersion && navigator.appVersion.split(' Edge/')[1].split(' ')[0]

  if (appVersionNumber && parseInt(appVersionNumber) < 17) {
    return false
  }

  return true
}

const sanitizeHtml = require('sanitize-html');

const htmlEntities = {
    nbsp: ' ',
    cent: '¢',
    pound: '£',
    yen: '¥',
    euro: '€',
    copy: '©',
    reg: '®',
    lt: '<',
    gt: '>',
    quot: '"',
    amp: '&',
    apos: '\''
};

export const decodeEntities = (str) => {
  /* eslint no-useless-escape: 0 */
  /* eslint no-cond-assign: 0 */
  /* eslint no-bitwise: 0 */
  if (!str) return str;
  return str.toString().replace(/\&([^;]+);/g, (entity, entityCode) => {
    let match;
    if (entityCode in htmlEntities) {
      return htmlEntities[entityCode];
    }
    if (match = entityCode.match(/^#x([\da-fA-F]+)$/)) {
      return String.fromCharCode(parseInt(match[1], 16));
    }
    if (match = entityCode.match(/^#(\d+)$/)) {
        return String.fromCharCode(~~match[1]);
    }
    return entity;
  });
};

export const stripHtmlTags = (content) => sanitizeHtml(content, { allowedTags: [], allowedAttributes: {} });

export const getExcerpt = (content, length) => {
  // Remove html tags
  const cleanedContent = stripHtmlTags(content);
  // Convert html entities to ascii, cut down content to desired length.
  const decodedTrimmedContent = decodeEntities(cleanedContent).substring(0, length);
  // If trimmed content is less than input length, add ellipsis
  const excerpt = decodedTrimmedContent.length === length ?
    `${decodedTrimmedContent.substring(0, decodedTrimmedContent.lastIndexOf(' '))}...` :
    decodedTrimmedContent;

  return excerpt;
};
